<template>
        <div class="login-wrapper">
            <div class="login-wrapper-inner">

                <div class="login-box">
                    <form @submit.prevent="login">
                        <div class="login-title">Iniciar Sesión</div>

                        <div class="form-group">
                            <label for="UserName">Usuario</label>
                            <input v-model="username" type="email" class="form-control" id="UserName" />
                        </div>

                        <div class="form-group">
                            <label for="Password">Contraseña</label>
                            <input v-model="password" type="password" class="form-control" id="Password">
                        </div>
<!-- 
                        <div class="form-group forgo-remember">
                            <div class="forgot-password">
                                <a href="#" class="txt1"> Forgot Password?</a>
                            </div>
                        </div> -->

                        <div class="form-group text-center">
                            <button type="submit" class="btn btn-dark">Entrar</button>
                        </div>
                    </form>
                    <!-- <h5 @click.prevent="$router.push('/public')" class="btn btn-black" style="padding:10px">ver cumpleañeros</h5> -->
                </div>

                <div class="copyright">
                    <!-- <p> &copy; 2018  <strong>Login Form</strong> . All rights reserved | Design by <a target="_blank" href="http://themeboxer.com">ThemeBoxer</a> </p> -->
                </div>

            </div>
        </div>  
</template>

<script>
export default {
    data(){
        return{
            username: '',
            password: ''
        }
    },
    methods:{
        async login(){
            try {
                let methodRequest
                let msg
                let path
                methodRequest= 'post'
                path = 'login'
                let options = {
                method: methodRequest,
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({username: this.username, password: this.password})
                }
                let req = await fetch(this.$store.state.url + path, options)
                let res = req.json()
                console.log(req)
                console.log(res)
                if (req.ok) {
                    //alert(req.)
                    this.$store.commit('updateUserRefresh');
                    this.$router.push('home')
                    localStorage.setItem('logged', true)
                }else{
                    alert('Error, intentalo nuevamente');
                }
            }
            catch(err){
                console.log(err)
                alert('Error, intentalo nuevamente');
            }
        }
    }
}
</script>

<style scoped>
/*
  Theme Name: Themeboxer | HTML5 Responsive Login Form 05 Template
  Theme URL: http://Themeboxer.com/
  Author: Themeboxer
  Author URI: http://www.Themeboxer.com
  Description: HTML5 Responsive Login Form Template 
  Version: 1.0
*/

/* Google Font Include */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

/*=============== Normalization Style ==============*/
    body{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        line-height: 1;
    }  
    h1,h2,h3,h4,h5,h6{
        margin: 0;
        padding: 0;
        line-height: .9;
        font-weight: 700;
        color: #34374c;
    }
    p{
        font-size: 16px;
        font-weight: 300;
        color: #fff;
        line-height: 1.2222;
    }
    ul, ol, li{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    a, a:hover,a:focus{
        display: inline-block;
        outline: none;
        text-decoration: none;
    }
    a:hover,a:focus{
        color: inherit;
    }
    a{
        -webkit-transition: all 700ms ease;
        -moz-transition: all 700ms ease;
        -ms-transition: all 700ms ease;
        -o-transition: all 700ms ease;
        transition: all 700ms ease;
    }
    img{
        width: 100%;
        height: auto;
    }
    /*=============== Start Wrapper Style ==============*/
    .login-wrapper {
        width: 100%;
        height: 300px;
        margin-top:175px !important;
        background: #d80e0ecc;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
    }

    .login-box{
        margin: auto;
        width: 600px;
        display: block;
        background: rgba(0, 0, 0, 0.7);
        padding: 2rem 5rem;
        margin-bottom: 5rem;
        margin-top:250px !important
    }
    .login-title {
        font-size: 30px;
        margin-bottom: 40px;
        color:#fff;
        letter-spacing: 2px;
        text-align: center;
        font-style: italic;
        font-weight: 800;
    }
    .login-box .form-group label{
        color: #fff;
        font-size: 14px;
        font-weight: 400;
    }
    .login-box .form-control:focus,
    .login-box .form-control{
        background-color: rgba(255, 255, 255, 0.2);
        height: 45px;
        border-color: rgba(255, 255, 255, 0.4);
        color: #fff;
    }
    .forgo-remember{
        display: flex;
        justify-content: space-between;
        -ms-align-items: center;
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
    }
    .forgot-password a{
        color: #fff;
    }
    .forgot-password a:hover{
        opacity: 0.8;
    }
    .copyright{
        text-align: center;
        padding: 15px;
    }
    .copyright a{
        font-weight: 600;
        color: #fff;
    }

    /*=============== Start Responsive Style ==============*/
    @media only screen and (min-width:0) and (max-width:768px) {
        .login-box {
            width: 100%;
            padding: 3rem 2rem;
            margin: 0;
            margin-top: 150px;

        }
    }

    @media only screen and (min-width:768px) and (max-width:1024px) {
        .login-box {
            width: 500px;
            padding: 5rem 4rem;
            margin-bottom: 1rem;
            margin-top: 150px;

        }
    }
    @media only screen and (min-width:1024px) and (max-width:1440px) {
        .login-box {
            width: 500px;
            padding:3rem 4rem;
            margin-bottom: 2rem;
            margin-top: 150px;
        }
    }
    .btn-black{
        background-color: black;
        color:white
    }


</style>